import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Box,
  Container,
  Section,
  Layout,
  Grid,
} from '../components';
import bannerImage from '../images/services/teamAugmentation/teamAugmentationBanner.jpg';
import styled from 'styled-components';
import contentImgOne from '../images/services/teamAugmentation/teamAug1.jpg';
import contentImgTwo from '../images/services/teamAugmentation/teamAug2.jpg';
import { margin, marginRight, marginTop, padding } from 'styled-system';
import ServiceHeroSection from '../components/ServiceHeroSection';

const ServiceContentImage = styled.img`
  box-shadow: 25px 25px 0px 0px var(--secondary-color);
  margin: 25px 0;
  width: 100%;

  @media screen and (max-width: 768px) {
    box-shadow: 10px 10px 0px 0px var(--secondary-color);
  }
`;

const teamAugmentation = () => {
  return (
    <Layout title="Team augmentaion">
      <Helmet>
        <meta
          name="description"
          content="Expand your team with skilled global tech talents, managed by you. Access vetted experts and a dedicated PM for project success and faster time-to-market."
        />
      </Helmet>
      <Container mt={{ xs: 8, md: 12 }}>
        <ServiceHeroSection
          title={'Team augmentaion'}
          imageSrc={bannerImage}
          imgAlt={'Team augmentaion'}
        />
        <Section>
          <Typography my={5} variant="paragraph1">
            Extend your team with an exciting team of global tech talents. Fill
            up the skill gaps to achieve both immediate and long-term business
            goals. Enhance the capacity of your team to scale.
          </Typography>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={contentImgOne} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                Our Team, You Manage
              </Typography>

              <Typography mt={2} variant="paragraph1">
                Access a large pool of tech talent ready to integrate into your
                existing teams. Our talents go through pre-employment
                assessments to ensure they possess the technical aptitude,
                logical thinking, and communication skills to integrate swiftly
                into your team.
              </Typography>
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box flip={true}>
              <Typography mt={2} variant="heading5">
                Your Project, Our Dedicated PM
              </Typography>

              <Typography mt={2} variant="paragraph1">
                A dedicated Project Manager (PM) to oversee the extended team,
                handle communication, and ensure project success. With our
                seasoned team and a dedicated PM, your project meets a better
                time to market.
              </Typography>
            </Box>
            <Box>
              <ServiceContentImage
                // style={{ float: 'right' }}
                src={contentImgTwo}
              />
            </Box>
          </Grid>
        </Section>
      </Container>
    </Layout>
  );
};

export default teamAugmentation;
